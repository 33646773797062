<template>
  <div class="quotes p-8 mx-auto max-w-5xl">
    <div class="space-y-6">
      <div class="flex justify-between pb-2 items-center">
        <h3 class="text-3xl font-semibold">
          Quote {{ quote.sequence_number }}
        </h3>

        <router-link
          v-if="!error"
          :to="`/quotes/${quote.id}/edit`"
          class="hover-trigger flex align-middle gap-1 text-blue-600 hover:text-blue-700"
        >
          <span class="hover-target pt-1 font-medium">Edit</span>
          <svg
            class="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
        </router-link>
      </div>

      <div v-if="isLoading" class="flex justify-center gap-1">
        <div class="spinner dark"></div>
        <div>Loading</div>
      </div>

      <div v-if="!error" class="shadow overflow-hidden border border-gray-200">
        <table
          class="w-full border-8 border-gray-600 divide-y-8 divide-gray-600"
        >
          <tr class="bg-gray-600">
            <td class="p-2 text-white font-semibold uppercase bg-gray-600">
              Internal Use
            </td>
          </tr>
          <tr>
            <td>
              <table class="w-full divide-y divide-gray-200">
                <thead>
                  <tr
                    class="bg-gray-100 text-sm font-medium text-gray-900 uppercase text-left"
                  >
                    <th class="px-6 py-4 text-center w-sm">PDF</th>
                    <th class="px-6 py-4 w-full">Assigned To</th>
                    <th class="px-6 py-4 w-sm">Status</th>
                    <th class="px-6 py-4 w-sm text-center">Accepted</th>
                    <th class="px-6 py-4 w-sm">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="hover:bg-gray-50 border-b text-left">
                    <td
                      class="px-6 py-3 flex justify-center gap-4"
                      v-if="service_lines && service_lines.length != 0"
                    >
                      <span v-if="!isPending" class="flex">
                        <span
                          v-if="!error"
                          :to="`/quotes/${quote.id}/edit`"
                          title="Download PDF"
                          @click="downloadPDF()"
                          class="hover-trigger flex align-middle gap-1 text-blue-600 hover:text-blue-700 cursor-pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            class="h-6 w-6 cursor-pointer"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                            />
                          </svg>
                        </span>

                        <router-link
                          class="hover-trigger flex justify-center align-middle gap-1 text-blue-600 hover:text-blue-700 cursor-pointer"
                          title="Send PDF"
                          :to="`${quote.id}/email`"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 cursor-pointer"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                            />
                          </svg>
                        </router-link>
                      </span>
                      <div v-if="isPending" class="spinner filled"></div>
                    </td>
                    <td class="px-6 py-3 flex justify-center gap-4" v-else>
                      <span title="No serives to print"> N/A </span>
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-500">
                      {{ assigned_to?.user_name || quote.assigned_to }}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-500 capitalize">
                      {{ quote.quote_status }}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-500 text-center">
                      {{ quote.accepted ? "&check;" : "" }}
                    </td>
                    <td
                      class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap font-semibold"
                    >
                      <span v-if="quote.override_price">*</span>
                      {{
                        Number(
                          quote.override_price
                            ? quote.override_price
                            : quote.total_price
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr
            v-if="
              quote.quote_notes?.length > 0 ||
                quote.customer?.customer_notes?.length > 0 ||
                quote.location?.location_notes?.length > 0
            "
          >
            <td>
              <table class="w-full">
                <tr
                  v-if="quote.quote_notes?.length > 0"
                  class="hover:bg-gray-50 border-b"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
                  >
                    Quote Notes
                  </td>
                  <td
                    class="px-6 py-4 text-sm text-gray-500 whitespace-pre-wrap w-full"
                  >
                    {{ quote.quote_notes }}
                  </td>
                </tr>

                <tr
                  v-if="quote.customer?.customer_notes?.length > 0"
                  class="hover:bg-gray-50 border-b"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
                  >
                    Customer Notes
                  </td>
                  <td
                    class="px-6 py-4 text-sm text-gray-500 whitespace-pre-wrap w-full"
                  >
                    {{ quote.customer.customer_notes }}
                  </td>
                </tr>

                <tr
                  v-if="quote.location?.location_notes?.length > 0"
                  class="hover:bg-gray-50 border-b"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
                  >
                    Location Notes
                  </td>
                  <td
                    class="px-6 py-4 text-sm text-gray-500 whitespace-pre-wrap w-full"
                  >
                    {{ quote.location.location_notes }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>

      <div
        v-if="!error && quote.customer"
        class="shadow overflow-hidden border border-gray-200"
      >
        <table class="w-full divide-y divide-gray-200">
          <thead>
            <tr
              class="bg-gray-100 text-sm font-medium text-gray-900 uppercase text-left"
            >
              <th colspan="2" class="px-6 py-4">
                <router-link
                  v-if="
                    user.claims.user_role == 'superadmin' ||
                      user.claims.user_role == 'masquerade' ||
                      user.claims.user_role == 'admin' ||
                      user.claims.user_role == 'office'
                  "
                  :to="`/customers/${quote.customer_id}`"
                  class="flex gap-1 align-middle hover-trigger"
                >
                  Customer
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-blue-500 hidden hover-target"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </router-link>
                <div v-else>
                  Customer
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Business Name
              </td>
              <td class="px-6 py-4 text-sm text-gray-500 w-full">
                {{ quote.customer.billing_business }}
              </td>
            </tr>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Name
              </td>
              <td class="px-6 py-4 text-sm text-gray-500">
                {{ quote.customer.customer_name }}
              </td>
            </tr>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Phone
              </td>
              <td class="px-6 py-4 text-sm text-gray-500">
                {{ quote.customer.customer_phone }}
              </td>
            </tr>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Email
              </td>
              <td class="px-6 py-4 text-sm text-gray-500">
                {{ quote.customer.customer_email }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="!error && quote.location"
        class="shadow overflow-hidden border border-gray-200"
      >
        <table class="w-full divide-y divide-gray-200">
          <thead>
            <tr
              class="bg-gray-100 text-sm font-medium text-gray-900 uppercase text-left"
            >
              <th colspan="2" class="px-6 py-4">
                <router-link
                  v-if="
                    user.claims.user_role == 'superadmin' ||
                      user.claims.user_role == 'masquerade' ||
                      user.claims.user_role == 'admin' ||
                      user.claims.user_role == 'office'
                  "
                  :to="`/locations/${quote.location_id}`"
                  class="flex gap-1 align-middle hover-trigger"
                >
                  Location
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-blue-500 hidden hover-target"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </router-link>
                <div v-else>
                  Location
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Address
              </td>
              <td class="px-6 py-4 text-sm text-gray-500 w-full">
                <div>{{ quote.location.location_address1 }}</div>
                <div v-if="quote.location.location_address2">
                  {{ quote.location.location_address2 }}
                </div>
                <div>
                  {{ quote.location.location_city }}
                  {{ quote.location.location_state }}
                  {{ quote.location.location_zip }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="!error && quote.attachments?.length > 0"
        class="shadow overflow-hidden border border-gray-200"
      >
        <table class="table-auto w-full divide-y divide-gray-200">
          <thead>
            <tr
              class="bg-gray-100 text-sm font-medium text-gray-900 uppercase text-left"
            >
              <th class="px-6 py-4">Attachments</th>
            </tr>
          </thead>
          <tbody>
            <tr class="hover:bg-gray-50 border-b">
              <td class="p-4">
                <div class="flex flex-wrap gap-1">
                  <div
                    v-for="image in quote.attachments"
                    :key="image"
                    @click="modalImage(`${image.url}`)"
                  >
                    <img :src="image.url" class="w-28 h-28 object-cover" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="!error" class="shadow overflow-hidden border border-gray-200">
        <table class="table-auto w-full divide-y divide-gray-200">
          <thead>
            <tr
              class="bg-gray-100 text-sm font-medium text-gray-900 uppercase text-left"
            >
              <th class="px-6 py-4">Service</th>
              <th class="px-6 py-4 w-full">Description</th>
              <th class="px-6 py-4 text-center">Qty</th>
              <th class="px-6 py-4 text-center">Price</th>
              <th class="px-6 py-4 text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="service_line of service_lines" :key="service_line">
              <tr class="hover:bg-gray-50 border-b">
                <td
                  class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap align-top"
                >
                  {{ service_line.service_name }}
                </td>
                <td
                  class="px-6 py-4 text-sm text-gray-500 w-full align-top whitespace-pre-wrap"
                >
                  {{ service_line.service_description }}
                </td>
                <td
                  class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap align-top text-right"
                >
                  {{ Number(service_line.quantity).toLocaleString("en-US") }}
                </td>
                <td
                  class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap align-top text-right"
                >
                  <div
                    v-if="
                      service_line.override_price != service_line.service_price
                    "
                    class="whitespace-nowrap"
                  >
                    {{
                      Number(service_line.override_price).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )
                    }}
                  </div>
                  <div
                    :class="{
                      'line-through':
                        service_line.override_price !=
                        service_line.service_price,
                    }"
                  >
                    {{
                      Number(service_line.service_price).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )
                    }}
                  </div>
                </td>
                <td
                  class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap align-top text-right"
                >
                  {{
                    Number(service_line.extended_price).toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                      }
                    )
                  }}
                </td>
              </tr>
              <tr
                v-if="service_line.images?.length > 0"
                class="hover:bg-gray-50 border-b"
              >
                <td colspan="5" class="p-4">
                  <div class="flex flex-wrap gap-1">
                    <div
                      v-for="image in service_line.images"
                      :key="image"
                      @click="modalImage(`${image.url}`)"
                    >
                      <img :src="image.url" class="w-28 h-28 object-cover" />
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <tr>
              <td colspan="3"></td>
              <td
                class="px-6 py-4 text-sm font-semibold text-gray-900 uppercase text-right"
              >
                Total
              </td>
              <td
                class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap font-semibold text-right"
              >
                <span v-if="quote.override_price">*</span>
                {{
                  Number(
                    quote.override_price
                      ? quote.override_price
                      : quote.total_price
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="text-center text-red-600 font-semibold">
        {{ error }}
      </div>

      <div>
        <hr class="border-b border-blue-500" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import useStorage from "@/composables/useStorage";
import getCollection from "@/composables/getCollection";
import getDocument from "@/composables/getDocument";
import axios from "axios";

export default {
  props: ["quote_id", "user"],
  emits: ["loadModalImage"],
  setup(props, context) {
    const isLoading = ref(true);
    const route = useRoute();
    const quoteId = ref(props.quote_id);
    const isPending = ref(false);
    const router = useRouter();
    const parent = route.path.substring(0, route.path.lastIndexOf("/"));

    const business_id = props.user.claims.business_id ?? "";

    const { error, document: quote } = getDocument(
      `businesses/${business_id}/quotes`,
      quoteId.value
    );

    const { error: business_err, document: business } = getDocument(
      "businesses",
      business_id
    );

    const { error: users_err, documents: users } = getCollection(
      `businesses/${business_id}/users`
    );

    const {
      error: service_lines_err,
      documents: service_lines,
    } = getCollection(
      `businesses/${business_id}/quotes/${quoteId.value}/service_lines`
    );

    const viewImage = async (imageUrl) => {
      return new Promise((resolve) => {
        const { getDownloadURL: getDownloadURL } = useStorage(
          business_id,
          "quotes"
        );

        getDownloadURL(imageUrl).then((url) => {
          resolve(url);
        });
      });
    };

    watch([quote, business, users, service_lines], () => {
      if (quote.value && business.value && users.value && service_lines.value) {
        isLoading.value = false;
      }

      // estimator should not see pending quotes
      if (
        props.user.claims.user_role == "estimator" &&
        quote.value.quote_status !== "assigned" &&
        quote.value.quote_status !== "processed"
      ) {
        router.push(parent);
      }
      // estimator should not see quotes not assigned to them
      if (
        props.user.claims.user_role == "estimator" &&
        quote.value.assigned_to &&
        quote.value.assigned_to !== props.user.uid
      ) {
        router.push(parent);
      }
    });

    const assigned_to = computed(() => {
      if (users.value) {
        return users.value
          .filter((u) => u.id == quote.value.assigned_to)
          .shift();
      }
      return null;
    });

    const downloadPDF = async () => {
      // const request = functions.httpsCallable("downloadPDF");
      let imagePath = [];
      if (business.value.business_logo) {
        imagePath = business.value.business_logo.split("/");
      }
      const { getDownloadURL: getDownloadURL } = useStorage(
        business_id,
        "assets"
      );
      isPending.value = true;

      await axios({
        url: `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/quote_downloadPDF`,
        method: "POST",
        data: {
          sequenceNumber: quote.value.sequence_number,
          businessLogo: business.value.business_logo ?? "",
          businessId: business_id,
          businessLogoName: imagePath[imagePath.length - 1] ?? "",
          quoteId: quoteId.value,
        },
      });

      const downloadableURL = await getDownloadURL(
        `${business_id}/pdfs/Quote_${quote.value.sequence_number}.pdf`
      );

      const link = document.createElement("a");
      link.setAttribute("href", downloadableURL);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", downloadableURL);

      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      isPending.value = false;
    };

    const modalImage = (data) => {
      context.emit("loadModalImage", data);
    };

    return {
      isLoading,
      route,
      error,
      quote,
      viewImage,
      business_err,
      users_err,
      users,
      isPending,
      service_lines_err,
      service_lines,
      assigned_to,
      downloadPDF,
      modalImage,
    };
  },
};
</script>
<style scoped>
.spinner.filled {
  border-top: 5px solid #3b82f6;
  border-right: 5px solid #3b82f6;
}
</style>
